<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Classify each of the following processes as either exothermic or endothermic:
      </p>

      <p class="mb-n3">Burning your chemistry notes at the end of the semester:</p>

      <v-radio-group v-model="inputs.input1">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.text"
          class="pl-6 py-0 my-0"
          :disabled="!allowEditing"
        />
      </v-radio-group>

      <p class="mb-n3">A chemical reaction occurring causing the container to get cold:</p>
      <v-radio-group v-model="inputs.input2">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.text"
          class="pl-6 py-0 my-0"
          :disabled="!allowEditing"
        />
      </v-radio-group>

      <p class="mb-n3">Two chemicals in a hand warmer package mixing causing it to feel warm:</p>
      <v-radio-group v-model="inputs.input3">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.text"
          class="pl-6 py-0 my-0"
          :disabled="!allowEditing"
        />
      </v-radio-group>

      <p class="mb-n3">The evaporation of water:</p>
      <v-radio-group v-model="inputs.input4">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.text"
          class="pl-6 py-0 my-0"
          :disabled="!allowEditing"
        />
      </v-radio-group>

      <p class="mb-n3">An egg cooking:</p>
      <v-radio-group v-model="inputs.input5">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.text"
          class="pl-6 py-0 my-0"
          :disabled="!allowEditing"
        />
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'Question75',

  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options: [
        {text: 'Endothermic', value: 'endothermic'},
        {text: 'Exothermic', value: 'exothermic'},
      ],
    };
  },
};
</script>
